/**
 * All route paths
 */
// Note: If changing route paths, add redirects to server.js.
let routes = {};
routes = {
  home: "/",
  root: "/",
  article: "/news/post/:slug",
  in_memoriam: "/news/in-memoriam",
  articles_category: "/news/:category",
  articles_subcategory: "/news/category/:subcategory",
  articles: "/news",
  viewposter: "/makealist/view",
  createlist: "/makealist/create",
  makealist: "/makealist",
  lists: "/what-to-watch",
  lists_category: "/what-to-watch/:category",
  lists_subcategory: "/what-to-watch/category/:subcategory",
  list: "/what-to-watch/post/:slug",
  makeposter: "/makealist/create-poster",
  collectdata: "/makealist/newsletter",
  subscribe: "/subscribe",
  subscribeResponse: "/thank-you",
  legal: "/legal",
  terms: "/terms-of-use",
  privacy: "/privacy",
  about: "/about",
  tag: "/tag/:tag",
  hub: "/hub/:slug",
};
export default routes;
