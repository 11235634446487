import React, { Suspense, lazy } from "react";
import { Switch } from "react-router-dom";
import { useIsMobile } from "./utilities/client/use-is-mobile-pure-js";
import { useIsTablet } from "./utilities/client/use-is-tablet-pure-js";
import { useIsIE } from "./utilities/client/use-is-ie-pure-js";
import Loader from "./components/Loader";
import routeNames from "./utilities/server/routeNames";

const Home = lazy(() => import("./routes/Home/index"));
const Articles = lazy(() => import("./routes/Articles/index"));
const Lists = lazy(() => import("./routes/Lists/index"));
const Page = lazy(() => import("./routes/Page/index"));
const SubscribePage = lazy(() => import("./routes/SubscribePage/index"));
const ArticlesByCategory = lazy(() => import("./routes/ArticlesByCategory/index"));
const ListsByCategory = lazy(() => import("./routes/ListsByCategory/index"));
const Article = lazy(() => import("./routes/Article/index"));
const List = lazy(() => import("./routes/List/index"));
const NotFound = lazy(() => import("./routes/NotFound/index"));
const CollectData = lazy(() => import("./routes/CollectData/client"));
const MakeList = lazy(() => import("./routes/MakeList/index"));
const CreateList = lazy(() => import("./routes/CreateList/index"));
const MakePoster = lazy(() => import("./routes/MakePoster/index"));
const ViewPoster = lazy(() => import("./routes/ViewPoster/index"));
const Tag = lazy(() => import("./routes/Tag/index"));
const Hub = lazy(() => import("./routes/Hub/index"));
const InMemoriam = lazy(() => import("./routes/InMemoriam/index"));

const AppRoute = ({ path, component: Component, ...other }) => {
  let props = Object.assign({}, other);
  return <Component {...props} />;
};

export const AppClient = () => {
  let clientContext = {
    isScrolled: false,
    isMobile: true,
    isTablet: false,
    heroHeight: 550,
    isIE: false,
  };

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isScrolled = false;
  // @TODO const isScrolled = useIsScrolled(); // Should stay boolean. Used for Sticky Nav.
  const isIE = useIsIE(); //(work on poly fills & move this to a utility function)
  const heroHeight =
    typeof window === "object" && window.visualViewport
      ? window.visualViewport.height
      : 550;
  // clientContext.isScrolled = isScrolled;
  clientContext.isMobile = isMobile;
  clientContext.isTablet = isTablet;
  clientContext.isScrolled = isScrolled;
  clientContext.heroHeight = heroHeight;
  clientContext.isIE = isIE;
  if (window.api === undefined) {
    window.api = {};
  }
  window.api.clientContext = clientContext;
  let context = window.api.context;

  // console.log("contexts", context, clientContext);

  // Hide client root, which is used to manage hidden hydration
  document.getElementById("client-root").style.display = "none";

  return (
    <Suspense fallback={<Loader centered={true} />}>
      <Switch>
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={[routeNames.in_memoriam, `${routeNames.in_memoriam}-2021`, `${routeNames.in_memoriam}-2022`, `${routeNames.in_memoriam}-2023`]}
          component={({ context, clientContext }) => (
            <InMemoriam context={context} clientContext={clientContext} />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.hub}
          component={({ context, clientContext }) => (
            <Hub context={context} clientContext={clientContext} />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.tag}
          component={({ context, clientContext }) => (
            <Tag context={context} clientContext={clientContext} />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.createlist}
          component={(props) => <CreateList {...props} />}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.viewposter}
          component={(props) => <ViewPoster {...props} />}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.makealist}
          component={(props) => <MakeList {...props} />}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.makeposter}
          component={(props) => <MakePoster {...props} />}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.collectdata}
          component={(props) => <CollectData {...props} />}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.article}
          component={({ context, clientContext }) => (
            <Article context={context} clientContext={clientContext} />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.list}
          component={({ context, clientContext }) => (
            <List context={context} clientContext={clientContext} />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.articles_category}
          component={({ context, clientContext }) => (
            <ArticlesByCategory
              context={context}
              clientContext={clientContext}
            />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.lists_category}
          component={({ context, clientContext }) => (
            <ListsByCategory context={context} clientContext={clientContext} />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.articles}
          component={({ context, clientContext }) => (
            <Articles context={context} clientContext={clientContext} />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.lists}
          component={({ context, clientContext }) => (
            <Lists context={context} clientContext={clientContext} />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.terms}
          component={({ context, clientContext }) => (
            <Page context={context} clientContext={clientContext} />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.legal}
          component={({ context, clientContext }) => (
            <Page context={context} clientContext={clientContext} />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.privacy}
          component={({ context, clientContext }) => (
            <Page context={context} clientContext={clientContext} />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.about}
          component={({ context, clientContext }) => (
            <Page context={context} clientContext={clientContext} />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.subscribeResponse}
          component={({ context, clientContext }) => (
            <Page context={context} clientContext={clientContext} />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path={routeNames.subscribe}
          component={({ context, clientContext }) => (
            <SubscribePage context={context} clientContext={clientContext} />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          exact
          path="/"
          component={({ context, clientContext }) => (
            <Home context={context} clientContext={clientContext} />
          )}
        />
        <AppRoute
          clientContext={clientContext}
          context={context}
          path="/*"
          component={({ context, clientContext }) => (
            <NotFound context={context} clientContext={clientContext} />
          )}
        />
      </Switch>
    </Suspense>
  );
};

export default AppClient;
